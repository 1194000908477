



























import Feature from '../mixins/Feature'
import mixins from 'vue-typed-mixins'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'
export default mixins(Feature).extend({
  name: 'Feature11',
  components: { DefaultButton }
})
